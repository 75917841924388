import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useAppDispatch } from '../../app/hooks';
import { setAccessToken } from '../../features/user/userSlice';
import styles from './VerifyCode.module.scss';
import CodeInput from './components/CodeInput/CodeInput';
import { ResendCodeService, VerifyCodeService } from '../../services';

const codeSchema = Yup.object().shape({
	code: Yup.string().required('Code is required'),
});

export default function VerifyCode({ operationId }) {
	const [reSendBtnDisabled, setResetBtnDisabled] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const submitHandler = values => {
		VerifyCodeService({ operationId, code: values.code }).then(
			({ data, status }) => {
				if (status === 200) {
					dispatch(setAccessToken(data.token));
				}
			},
		);
	};

	const resendCode = () => {
		setResetBtnDisabled(true);
		ResendCodeService({ operationId }).then(({ data, status }) => {
			if (status === 200) {
				setResetBtnDisabled(false);
			}
		});
	};

	return (
		<div className={styles.main}>
			<Formik
				initialValues={{
					code: '',
				}}
				validationSchema={codeSchema}
				onSubmit={submitHandler}
			>
				{({ values, setFieldValue }) => (
					<Form className={styles.form} noValidate>
						<div className={styles.form__form1}>
							<span className={styles.title}>
								Please enter the code sent to your email.
							</span>

							<div>
								<CodeInput setFieldValue={setFieldValue} />
							</div>
						</div>

						<div>
							<button
								className={`${styles.form__button} ${
									values.code
										? styles.form__active
										: styles.form__disabled
								}`}
								type="submit"
							>
								verify
							</button>
						</div>
						<div className={styles.footer}>
							<p className={styles.caption}>
								If you did not receive a code within 2 minutes,
								select to resent or sign in as a new Admin.
							</p>
							<div
								className={`${styles.resentButton} ${
									reSendBtnDisabled
										? styles.resentButtonDisabled
										: ''
								}`}
								onClick={() => resendCode()}
								role="button"
							>
								Resent Code
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
