import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const SigninService: (body: {
	email: string;
	password: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.SIGNIN,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: true,
	});
};
const LogoutService: (
	token: string,
) => Promise<{ data: any; status: number }> = async token => {
	return fetchService({
		url: Apis.LOGOUT,
		method: 'POST',
		addContentTypeApplicationJson: true,
		token: token,
	});
};
const VerifyCodeService: (body: {
	operationId: string;
	code: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.VERIFY_CODE,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};
const ResendCodeService: (body: {
	operationId: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.RESEND_CODE,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: true,
	});
};

export { SigninService, LogoutService, VerifyCodeService, ResendCodeService };
