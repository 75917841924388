import React, { useRef, useState, useEffect } from 'react';
import styles from './CodeInput.module.scss';

export default function CodeInput({ setFieldValue }) {
	const inp1 = useRef(null); 
	const inp2 = useRef(null);
	const inp3 = useRef(null);
	const inp4 = useRef(null);
	const [code, setCode] = useState<{
		c1: string;
		c2: string;
		c3: string;
		c4: string;
	}>({
		c1: '',
		c2: '',
		c3: '',
		c4: '',
	});

	useEffect(() => {
		let resultCode = '';
		if ((code.c1, code.c2, code.c3, code.c4)) {
			resultCode = `${code.c1}${code.c2}${code.c3}${code.c4}`;
		}
		setFieldValue('code', resultCode);
	}, [code.c1, code.c2, code.c3, code.c4]);
	return (
		<div className={styles.main}>
			<div className={styles.inputContainer}>
				<input
					ref={inp1}
					value={code.c1}
					onChange={e => {
						const val = e.target.value;
						if (val === '' || /^[0-9]$/.test(val)) {
							setCode({
								...code,
								c1: val,
							});
							if (val.length === 1 && inp2) {
								(inp2.current as any).focus();
							}
						}
					}}
				/>
				<input
					ref={inp2}
					value={code.c2}
					onChange={e => {
						const val = e.target.value;
						if (val === '' || /^[0-9]$/.test(val)) {
							setCode({
								...code,
								c2: val,
							});
							if (val.length === 1 && inp3) {
								(inp3.current as any).focus();
							} else {
								(inp1.current as any).focus();
							}
						}
					}}
				/>
				<input
					ref={inp3}
					value={code.c3}
					onChange={e => {
						const val = e.target.value;
						if (val === '' || /^[0-9]$/.test(val)) {
							setCode({
								...code,
								c3: val,
							});
							if (val.length === 1 && inp4) {
								(inp4.current as any).focus();
							} else {
								(inp2.current as any).focus();
							}
						}
					}}
				/>
				<input
					ref={inp4}
					value={code.c4}
					onChange={e => {
						const val = e.target.value;
						if (val === '' || /^[0-9]$/.test(val)) {
							setCode({
								...code,
								c4: val,
							});
							if (val === '' && inp3) {
								(inp3.current as any).focus();
							}
						}
					}}
				/>
			</div>
		</div>
	);
}
