import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './LeftBarMenu.module.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
	selecedAccessToken,
	setAccessToken,
} from '../../features/user/userSlice';
import { LogoutService } from '../../services';

export default function LeftBarMenu(): ReactElement {
	const pathName = useLocation().pathname;
	const accessToken = useAppSelector(selecedAccessToken);
	const dispatch = useAppDispatch();
	const logoutProccess = () => {
		LogoutService(accessToken).then(({ data, status }) => {
			localStorage.clear();
			dispatch(setAccessToken(''));
		});
	};
	return (
		<div className={styles.root}>
			{accessToken && accessToken.length > 0 && (
				<>
					<Link
						to="/"
						className={`${styles.menuItem} ${
							pathName === '/' ? styles.menuItem__active : ''
						}`}
					>
						<span>Manage Advisors</span>
					</Link>
					<Link
						to="/clients"
						className={`${styles.menuItem} ${
							pathName === '/clients'
								? styles.menuItem__active
								: ''
						}`}
					>
						<span>Manage Clients</span>
					</Link>
					<Link
						to="/approve-busines-suite"
						className={`${styles.menuItem} ${
							pathName === '/approve-busines-suite'
								? styles.menuItem__active
								: ''
						}`}
					>
						<span>Business Suite</span>
					</Link>
					<Link
						to="/admins"
						className={`${styles.menuItem} ${
							pathName === '/admins'
								? styles.menuItem__active
								: ''
						}`}
					>
						<span>Admins</span>
					</Link>
					<div className={styles.divider} />
					<div onClick={logoutProccess} className={styles.menuItem}>
						<span>Sign Out</span>
					</div>
				</>
			)}
		</div>
	);
}
