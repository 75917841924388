const BaseApi = process.env.REACT_APP_BASE_API;

const Apis = {
	SIGNIN: `${BaseApi}/signin-admin`,
	LOGOUT: `${BaseApi}/admin/logout`,
	REVIEWS: `${BaseApi}/admin/reviews`,
	ADVISORS: `${BaseApi}/admin/advisors`,
	CLIENTS: `${BaseApi}/admin/users`,
	ADMINS: `${BaseApi}/admin/admins`,
	ADMIN_HISTORY: `${BaseApi}/admin/admin-history`,
	BUSINESS_CLIENTS: `${BaseApi}/admin/business-users`,
	VERIFY_ADVISORS: `${BaseApi}/admin/toggle-verify-advisor`,
	VERIFY_CLIENTS: `${BaseApi}/admin/toggle-verify-user`,
	ENABLE_DISABLE_REVIEW_CLIENTS: `${BaseApi}/admin/toggle-review-enable`,
	APPROVE_REJECT_BUSINESS_SUITE: `${BaseApi}/admin/approve-reject-business-suite`,
	USERPROFILE: `${BaseApi}/admin/get-user`,
	SERVICEIDS: `${BaseApi}/admin/get-sevices-list`,
	GETADVISOR: `${BaseApi}/admin/get-advisor`,
	ADDADVISOR: `${BaseApi}/admin/create-ghost-advisor`,
	UPDATEADVISOR: `${BaseApi}/admin/update-advisor`,
	VERIFY_CODE: `${BaseApi}/verify-code-admin`,
	RESEND_CODE: `${BaseApi}/resend-code-admin`,
};
export { BaseApi, Apis };
