import React, { lazy, ReactElement, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import Nav from './common/Nav';
import Auth from './components/auth/Auth';
import RouteLoader from './components/routeLoader/RouteLoader';
import LeftBarMenu from './components/LeftBarMenu/LeftBarMenu';

const routes = [
	{
		path: '/',
		component: lazy(() => import('./routes/advisorListNew/AdvisorListNew')),
		exact: true,
	},
	{
		path: '/clients',
		component: lazy(() => import('./routes/clientList/ClientList')),
	},
	{
		path: '/approve-busines-suite',
		component: lazy(
			() => import('./routes/clientList/ClientAprroveReject'),
		),
	},
	{
		path: '/',
		component: lazy(() => import('./routes/home/Home')),
		exact: true,
	},
	{
		path: '/advisors/:advisorId/reviews/create',
		component: lazy(() => import('./routes/reviewEdit/ReviewEdit')),
	},
	{
		path: '/advisors/:advisorId/reviews/:reviewId/edit',
		component: lazy(() => import('./routes/reviewEdit/ReviewEdit')),
	},
	{
		path: '/advisors/:id/hcp',
		component: lazy(() => import('./routes/advisorHcp/AdvisorHcp')),
	},
	{
		path: '/advisors/:id',
		component: lazy(() => import('./routes/advisorDetail/AdvisorDetail')),
	},
	{
		path: '/advisors',
		component: lazy(() => import('./routes/advisorList/AdvisorList')),
	},
	{
		path: '/reviews',
		component: lazy(
			() => import('./routes/reviewRequestList/ReviewRequestList'),
		),
	},
	{
		path: '/affiliates/create',
		component: lazy(() => import('./routes/affiliateEdit/AffiliateEdit')),
	},
	{
		path: '/affiliates/:affiliateId/edit',
		component: lazy(() => import('./routes/affiliateEdit/AffiliateEdit')),
	},
	{
		path: '/affiliates',
		component: lazy(() => import('./routes/affiliateList/AffiliateList')),
	},
	{
		path: '/budget-reports',
		component: lazy(() => import('./routes/budgetReports/BudgetReports')),
	},
	{
		path: '/ghost-advisors',
		component: lazy(() => import('./routes/ghostAdvisors/GhostAdvisors')),
	},
	{
		path: '/ghost-advisors-manage',
		component: lazy(() => import('./routes/ghostAdvisors/Manage')),
	},
	{
		path: '/ghost-advisors-create',
		component: lazy(() => import('./routes/ghostAdvisors/Create')),
	},
	{
		path: '/ghost-advisors-edit/:id',
		component: lazy(() => import('./routes/ghostAdvisors/Edit')),
	},
	{
		path: '/admins',
		component: lazy(() => import('./routes/adminsList/AdminsList')),
	},
];

export default function App(): ReactElement {
	return (
		<div className="app">
			<Router>
				<Nav>
					<LeftBarMenu />
				</Nav>
				<Suspense fallback={<RouteLoader />}>
					<Auth>
						<Switch>
							{routes.map((route, index) => (
								<Route
									key={index}
									exact={route.exact ?? false}
									path={route.path}
									component={route.component}
								/>
							))}
						</Switch>
					</Auth>
				</Suspense>
			</Router>
			<ToastContainer />
		</div>
	);
}
