import {
	Action,
	configureStore,
	createSlice,
	ThunkAction,
} from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';

const SESSION_KEY = 'PERLA_STORE';

const sessionStorageMiddleware = store => next => action => {
	let result = next(action);
	sessionStorage.setItem(SESSION_KEY, JSON.stringify(store.getState()));
	return result;
};

// we're defining a custom slice to maintain compatilibity with a common component
const searchSlice = createSlice({
	name: 'search',
	initialState: {
		sentLeadsAdvisorIds: [],
	} as any,
	reducers: {},
});

export const store = configureStore({
	reducer: {
		user: userReducer,
		search: searchSlice.reducer, // just used for compatibility with shared component
	},
	preloadedState: JSON.parse(sessionStorage.getItem(SESSION_KEY) || '{}'),
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat([sessionStorageMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
