import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllClientsService: (
	token: string,
	showSuccessMessage: boolean,
) => Promise<{ data: any; status: number }> = async (
	token,
	showSuccessMessage,
) => {
	return fetchService({
		url: Apis.CLIENTS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		token: token,
		showSuccessMessage: showSuccessMessage,
	});
};
const GetAllBusinessClientsService: (
	token: string,
	showSuccessMessage: boolean,
) => Promise<{ data: any; status: number }> = async (
	token,
	showSuccessMessage,
) => {
	return fetchService({
		url: Apis.BUSINESS_CLIENTS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		token: token,
		showSuccessMessage: showSuccessMessage,
	});
};
const DeleteClientsService: (
	token: string,
	userId: string,
) => Promise<{ data: any; status: number }> = async (token, userId) => {
	return fetchService({
		url: Apis.CLIENTS,
		method: 'DELETE',
		body: { userId },
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};
const VerifyClientsService: (
	token: string,
	userId: string,
) => Promise<{ data: any; status: number }> = async (token, userId) => {
	return fetchService({
		url: Apis.VERIFY_CLIENTS,
		method: 'POST',
		body: { userId },
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};
const ToggleEnableReviewClientsService: (
	token: string,
	userId: string,
) => Promise<{ data: any; status: number }> = async (token, userId) => {
	return fetchService({
		url: Apis.ENABLE_DISABLE_REVIEW_CLIENTS,
		method: 'POST',
		body: { userId },
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};

const ApproveBusinessSuite: (
	token: string,
	userId: string,
	businessSuiteApproval: string,
) => Promise<{ data: any; status: number }> = async (
	token,
	userId,
	businessSuiteApproval,
) => {
	return fetchService({
		url: Apis.APPROVE_REJECT_BUSINESS_SUITE,
		method: 'POST',
		body: { userId, businessSuiteApproval },
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};

const GetUserProfile: (
	token: string,
	userId: string,
) => Promise<{ data: any; status: number }> = async (token, userId) => {
	return fetchService({
		url: `${Apis.USERPROFILE}/${userId}`,
		method: 'GET',
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};

export {
	GetAllClientsService,
	DeleteClientsService,
	VerifyClientsService,
	ToggleEnableReviewClientsService,
	ApproveBusinessSuite,
	GetUserProfile,
	GetAllBusinessClientsService,
};
