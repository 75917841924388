import { Apis } from "../constants/Apis";
import { fetchService } from './service';


const GetAllAdvisorReviewsService: (token: string, advisotId: string, showSuccessMessage: boolean) =>
    Promise<{ data: any, status: number }> = async (token, advisotId, showSuccessMessage) => {
        return fetchService({
            url: `${Apis.REVIEWS}/${advisotId}`, method: "GET",
            addContentTypeApplicationJson: false,
            token: token, showSuccessMessage: showSuccessMessage
        })
    }
const DeleteReviewService: (token: string, reviewId: string) =>
    Promise<{ data: any, status: number }> = async (token, reviewId) => {
        return fetchService({
            url: Apis.REVIEWS, method: "DELETE",
            body: { reviewId },
            addContentTypeApplicationJson: true,
            token: token, showSuccessMessage: true
        })
    }

export {
    GetAllAdvisorReviewsService, DeleteReviewService
}