import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserState {
	accessToken: string;
}

const initialState: UserState = {
	accessToken: '',
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
	},
});

export const { setAccessToken } = userSlice.actions;
export const selecedAccessToken = (state: RootState) => state.user.accessToken;

export default userSlice.reducer;
