import React, { InputHTMLAttributes, useState, useEffect } from "react";
import useStyles from "./index.styles";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "./inputCss.css";

export type inputType =
  | "number"
  | "textOnly"
  | "nationalcode"
  | "mobile"
  | "price"
  | "en"
  | "password"
  | "date";

export interface PerlaInputProps {
  /**
   *state
   */
  state?: number | string | null;
  /**
   *plceholder
   */
  placeholder?: string;
  /**
   * label
   */
  label?: string;

  /**
   * classes
   */
  classes?: {
    base?: string;
    label?: string;
    input?: string;
  };
  /**
   * onChange
   */
  onChange?: (value: any) => void;
  /**
   * onKeyDown
   */
  onKeyDown?: (value: any) => void;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  /**
   * id
   */
  id?: number;
  /**
   * max
   */
  max?: string;
  /**
   * min
   */
  min?: string;

  /**
   * type
   */
  type?: inputType;
  /**
   * maxLength
   */
  maxLength?: number;
  /**
   * minLength
   */
  minLength?: number;
  /**
   * disabled
   */
  disabled?: boolean;
  /**
   * readOnly
   */
  readOnly?: boolean;
  /**
   * required
   */
  required?: boolean;
  /**
 * queries
 */
  queries?: { text: string; field: any }[];
  /**
   * outputType
   */
  outputType?: "string" | "number";
  /**
   * errorMessage
   */
  errorMessage?: string;
  /**
   * clearFlag
   */
  clearFlag?: boolean;
}
export function PerlaInput({
  state,
  onChange,
  label,
  placeholder,
  classes,
  inputProps,
  type,
  maxLength,
  minLength,
  onKeyDown,
  disabled,
  required,
  outputType,
  errorMessage,
  id,
  queries,
  readOnly,
  clearFlag,
  max,
  min,
}: PerlaInputProps) {
  const myClasses = useStyles();

  const [innerState, setInnerState] = useState<string | number>("");
  const [firstRender, setFirstRender] = useState<boolean>(false);

  //  initial state
  useEffect(() => {
    state && setInnerState(state);
  }, []);

  // clear flag
  useEffect(() => {
    if (firstRender) {
      setInnerState("");
      onChange && onChange("");
      setFirstRender(true);
    }
  }, [clearFlag]);

  //################################## توابع

  // number mode
  const numberHandler = (value: string) => {
    let outPut;

    if (maxLength) {
      outPut = value.slice(0, maxLength);
    } else {
      if (value.length < 20) {
        outPut = value;
      } else {
        outPut = value.slice(0, 21);
      }
    }
    setInnerState(outPut);
    if (outputType === "number") {
      onChange && onChange(parseInt(outPut));
    } else {
      onChange && onChange(outPut);
    }
  };

  // text only mode
  const textOnlyHandler = (value: string) => {
    // if (/^[A-Za-zÑñ-\s]*$/i.test(value)) {
    setInnerState(value);
    onChange && onChange(value);
    // } else {
    // setInnerState(value.slice(0, value.length - 1));
    // }
  };

  const onBlurHandler = (value: string) => {
    let output;
    if (type === "number") {
      if (max && Number(value) > Number(max)) {
        output = max;
      } else if (min && Number(value) < Number(min)) {
        output = min;
      }

      if (output) {
        setInnerState(output);
        if (outputType === "number") {
          onChange && onChange(parseInt(output));
        } else {
          onChange && onChange(output);
        }
      }
    }
  };


  return (
    <div
      // className={[myClasses.root, classes?.base ? classes.base : ""].join(" ")}
      className={clsx(myClasses.perlaInputRoot, {
        [classes?.base ?? ""]: classes?.base,
      })}
    >
      {label && (
        <Typography
          variant="body2"
          className={clsx(myClasses.label, {
            [classes?.label ?? ""]: classes?.label,
          })}
        >
          {required && <span className={myClasses.requiredSpan}>* </span>}
          {`${label}:`}
        </Typography>
      )}
      <input
        value={innerState}
        readOnly={readOnly}
        type={
          type && ["number", "mobile", "nationalcode"].includes(type)
            ? "number"
            : type && ["password"].includes(type)
              ? "password"
              : undefined
        }
        {...inputProps}
        onKeyPress={(evt) => {
          if (type === "number" && evt.which === 101) {
            evt.preventDefault();
          }
        }}
        onChange={(e) => {
          switch (type) {
            case "textOnly": {
              textOnlyHandler(e.target.value);
              break;
            }
            case "number": {
              numberHandler(e.target.value);
              break;
            }
            default:
              let outPut;
              if (e.target.value.length < 500) {
                outPut = e.target.value;
              } else {
                outPut = e.target.value.slice(0, 500);
              }
              setInnerState(outPut);
              onChange && onChange(outPut);
          }
        }}
        className={clsx(myClasses.input, {
          [classes?.input ?? ""]: classes?.input,
        })}
        autoComplete="off"
        maxLength={maxLength ? maxLength : undefined}
        max={max ? max : undefined}
        min={min ? min : undefined}
        minLength={minLength ? minLength : undefined}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        required={required ? required : false}
        style={{
          border:
            errorMessage && errorMessage.length > 0
              ? "1px solid red"
              : "1px solid #c4c4c4",
        }}
        onBlur={(e) => onBlurHandler(e.target.value)}
        onKeyDown={onKeyDown}
      />
      {errorMessage && errorMessage.length > 0 && (
        <Typography className={myClasses.PerlaInputErrorText} color="error">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
}
