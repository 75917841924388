import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllAdvisorsService: (
	token: string,
	showSuccessMessage: boolean,
) => Promise<{ data: any; status: number }> = async (
	token,
	showSuccessMessage,
) => {
		return fetchService({
			url: Apis.ADVISORS,
			method: 'GET',
			addContentTypeApplicationJson: false,
			token: token,
			showSuccessMessage: showSuccessMessage,
		});
	};
const DeleteAdvisorsService: (
	token: string,
	advisorId: string,
) => Promise<{ data: any; status: number }> = async (token, advisorId) => {
	return fetchService({
		url: Apis.ADVISORS,
		method: 'DELETE',
		body: { advisorId },
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};
const VerifyAdvisorsService: (
	token: string,
	advisorId: string,
) => Promise<{ data: any; status: number }> = async (token, advisorId) => {
	return fetchService({
		url: Apis.VERIFY_ADVISORS,
		method: 'POST',
		body: { advisorId },
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};
const GetAdvisorDetailsService: (
	token: string,
	advisorId: string,
	showSuccessMessage: boolean,
) => Promise<{ data: any; status: number }> = async (
	token,
	advisorId,
	showSuccessMessage,
) => {
		return fetchService({
			url: `${Apis.GETADVISOR}/${advisorId}`,
			method: 'GET',
			addContentTypeApplicationJson: false,
			token: token,
			showSuccessMessage: showSuccessMessage,
		});
	};
const AddAdvisorService: (
	token: string,
	formData: any,
) => Promise<{ status: number }> = async (token, formData) => {
	return fetchService({
		url: Apis.ADDADVISOR,
		method: 'POST',
		body: {
			email: formData.email,
			first_name: formData.first_name,
			last_name: formData.last_name,
			website_url: formData.website_url,
			business_name: formData.business_name,
			business_address: formData.business_address,
			licenses: formData.licenses,
			service_ids: formData.service_ids,
			professional_title: formData.professional_title,
			professional_bio: formData.professional_bio,
			profession: formData.profession,
			phone: formData.phone,
			practice_areas: formData.practiceAreas,
			educations: formData.educations,
		},
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};
const UpdateAdvisorService: (
	token: string,
	formData: any,
	advisorId: string
) => Promise<{ status: number }> = async (token, formData, advisorId) => {
	return fetchService({
		url: Apis.UPDATEADVISOR,
		method: 'POST',
		body: {
			advisorId: advisorId,
			email: formData.email,
			first_name: formData.first_name,
			last_name: formData.last_name,
			website_url: formData.website_url,
			business_name: formData.business_name,
			business_address: formData.business_address,
			licenses: formData.licenses,
			service_ids: formData.service_ids,
			professional_title: formData.professional_title,
			professional_bio: formData.professional_bio,
			profession: formData.profession,
			phone: formData.phone,
			practice_areas: formData.practiceAreas,
			educations: formData.educations,
		},
		addContentTypeApplicationJson: true,
		token: token,
		showSuccessMessage: true,
	});
};
const GetServiceIds: (
	token: string,
	showSuccessMessage: boolean,
) => Promise<{ data: any; status: number }> = async (
	token,
	showSuccessMessage,
) => {
		return fetchService({
			url: Apis.SERVICEIDS,
			method: 'GET',
			addContentTypeApplicationJson: false,
			token: token,
			showSuccessMessage: showSuccessMessage,
		});
	};
const GetAdvisorReviewsService: (
	advisorId: string,
	page: number,
	showSuccessMessage: boolean,
) => Promise<{ data: any; status: number }> = async (
	advisorId,
	page = 1,
	showSuccessMessage,
) => {
		return fetchService({
			url: `${Apis.REVIEWS}/${advisorId}?page=${page}`,
			method: 'GET',
			addContentTypeApplicationJson: false,
			showSuccessMessage: showSuccessMessage,
		});
	};
export {
	GetAllAdvisorsService,
	DeleteAdvisorsService,
	VerifyAdvisorsService,
	GetAdvisorReviewsService,
	GetServiceIds,
	AddAdvisorService,
	UpdateAdvisorService,
	GetAdvisorDetailsService
};
