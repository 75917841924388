import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  perlaInputRoot: {
    marginBottom: 10,
  },
  label: {
    color: theme.palette.text.primary,
  },
  input: {
    height: 35,
    backgroundColor: theme.palette.background.default,
    maxWidth: 250,
    width: "100%",
    borderRadius: 6,
    marginTop: 5,
    paddingLeft: 10,
    boxSizing: "border-box",
    fontSize: 16,
    border: `2px solid ${theme.palette.secondary.main}`,
    "&:focus": {
      outline: "none",
      border: `1px solid ${theme.palette.secondary.main}`,

    },
    "&::placeholder ": {
      // color: `${theme.palette.text.white}bb`,
      fontSize: 12,
    },
  },
  PerlaInputErrorText: {
    fontSize: 12,
    marginTop: 5,
  },
  requiredSpan: {
    color: "red"
  }
}));

export default useStyles;
