import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selecedAccessToken } from '../../features/user/userSlice';
import { SigninService } from '../../services';
import { PerlaInput } from '../PerlaInputNew';
import styles from './Auth.module.scss';
import VerifyCode from '../VerifyCode/VerifyCode';

interface AuthProps {
	children: any;
}

export default function AuthComponent({ children }: AuthProps) {
	const accessToken = useAppSelector(selecedAccessToken);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [operationId, setOperationId] = useState<string>('');
	const signinProccess = () => {
		SigninService({ email, password }).then(({ data, status }) => {
			if (status === 200) {
				setOperationId(data.operationId);
			}
		});
	};
	useEffect(() => {
		setOperationId('');
	}, [accessToken]);
	return (
		<section id="main">
			{accessToken && accessToken.length > 0 ? (
				children
			) : operationId ? (
				<VerifyCode operationId={operationId} />
			) : (
				<div className={styles.card}>
					<span className={styles.card__title}>Sign In as admin</span>
					<PerlaInput
						state={email}
						onChange={value => {
							setEmail(value);
						}}
						label="Email/Username"
						classes={{
							base: styles.inputContainer,
							input: styles.input,
						}}
					/>
					<PerlaInput
						state={password}
						onChange={value => {
							setPassword(value);
						}}
						label="Password"
						classes={{
							base: styles.inputContainerPassword,
							input: styles.input,
						}}
						type="password"
					/>
					<div
						onClick={signinProccess}
						className={`${styles.card__button} ${styles.card__guest}`}
					>
						<span className={styles.card__button__label}>
							Sign In
						</span>
					</div>
				</div>
			)}
		</section>
	);
}
